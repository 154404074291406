import { graphql } from 'gatsby';
import { arrayOf, shape, string } from 'prop-types';
import React from 'react';
import BEMHelper from 'react-bem-helper';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { Content } from '../../common/content';
import { pictureFluidType } from '../../../types/picture';
import { FluidPicture } from '../../common/picture';
import './material.scss';

const bem = new BEMHelper({
  name: 'content-material',
});

const STYLE_RESET = {
  position: '',
  top: '',
  left: '',
  width: '',
  height: '',
  objectFit: '',
  overflow: '',
};

export const Material = ({ id, materials, materialMainImage }) => {

  const { t } = useTranslation();

  return (
    <Content id={id} {...bem()}>
      <div {...bem('main')}>

        {materialMainImage && (
          <div {...bem('fullimage')}>
            <FluidPicture
              aria-hidden="false"
              style={STYLE_RESET}
              imgStyle={STYLE_RESET}
              widths={[60, 120, 240]}
              maxWidth={120}
              {...materialMainImage[0]}
            />
          </div>
        )}

        {materials.map(({
          headline,
          description,
          icon
        }, index) => {
          return (
            <div key={'materialkey-' + index} {...bem('item', '' + (index+1))}>
              <div {...bem('image')}>
                <FluidPicture
                  {...bem('icon')}
                  role="icon"
                  aria-hidden="false"
                  style={STYLE_RESET}
                  imgStyle={STYLE_RESET}
                  widths={[90, 130, 240]}
                  maxWidth={120}
                  {...icon[0]}
                />
              </div>
              <div {...bem('text')}>
                <div {...bem('headline')}>
                  {headline}
                </div>
                <div {...bem('description')}>
                  {parse(description)}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </Content>
  );
};

Material.propTypes = {
  id: string.isRequired,
  materials: arrayOf(shape({
    warning: string,
    icon: arrayOf(shape({
      headline: string,
      description: string,
    })).isRequired,
  })).isRequired,
};

export const fragment = graphql`
  fragment MaterialContentFragment on CraftGQL_pageContents_materialTeaser_BlockType {
    id
    materialMainImage {
      id
      url
      ...Picture
    }
    materials {
      ...on CraftGQL_pageContentsMaterial_contentsMaterial_Entry {
        id
        typename: __typename
        headline: materialHeadline
        description: materialDescription
        icon: materialIcon {
          url
          ...Picture
          fluid_0: url(width: 60, mode: "crop", position: "") # 0.25
          fluid_1: url(width: 120, mode: "crop", position: "") # 0.5
          fluid_2: url(width: 240, mode: "crop", position: "") # max width
        }
      }
    }
  }
`;
